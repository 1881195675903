import React from "react";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import {MenuItem} from "@material-ui/core";
import Checkout from "../comp-payments/checkout";
import fetchPrice from "../comp-orderSummary/fetchPrice";
import calculateTotalPrice from "../comp-pricing/pricingCalculator";
import localStorageFunctions from "../comp-localStorage/localStroage";
import "@babel/polyfill";
import {secondMattingFactorialPrice} from "../comp-pricing/secondaryMat";
import showDollarPerUnit from "../comp-orderSummary/showDollarPerUnit";
import analyticsDataToDB from "../comp-analyticsLog/dbLogging";
import FormControl from '@material-ui/core/FormControl';
// import visa from "../../../resources/svgIcons/cc-visa-brands.svg";
// import mastercard from "../../../resources/svgIcons/cc-mastercard-brands.svg";
// import amex from "../../../resources/svgIcons/cc-amex-brands.svg";
// import discover from "../../../resources/svgIcons/cc-discover-brands.svg";
// import stripe from "../../../resources/svgIcons/cc-stripe-brands.svg";
import {productDB} from "../../../javascript/components/comp-pricing/productDB";
import ShippingInfo from "./shippingInfo";
import ShoppingSummary from "./shoppingSummary";
import connectToDB from "../database/connectDB";
import {cadUsdRate} from "../../components/comp-pricing/forExRate";

export default class PaymentApp extends React.Component {

  constructor() {
    super();
    this.state = {
      Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4: "",
      yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc: "",
      yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc: "",
      MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk: "",
      ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: "Select",
      MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw: "CA",
      ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78: "",
      shippingAddressPostalCode: "",
      eApOtsVDv3CBJxgQ074pEw: "",
      yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr: "",
      totalCost: 0,
      qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw: localStorageFunctions("qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw", null),
      cadUsdRate: cadUsdRate,
      fetchSpinner: false,
      //this state shows if a user has selected a province on a previous page. if not (null from local storage), then we will let them
      //choose a US state on this page.
      provinceSelected: false,
      promoCodeApplied: false,
      promoCodeEntered: "",
      discountRate: 0,
      pickUpOrShipping: "shipping",
      focusedModal: "shipping",
      shippingCharges: 0,
      //if below is false, that means the shipping entry has been confirmed and checked
      turnOffErrorChecker: true,
      //shipping, summary, and payments
      orderConfirmationNumber: `${localStorage.getItem("B50A23F61860P0353E50I82873C6CD2RDDC401B1")} - ${(Math.random() * 1000000).toFixed(0)} `,
      french: false,
    };

    this.addTextFields = this.addTextFields.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.evaluatePromoCode = this.evaluatePromoCode.bind(this);
    this.parentSetStateCallback = this.parentSetStateCallback.bind(this);
    this.selectProvinceOrState = this.selectProvinceOrState.bind(this);
    this.shippingInfoCheck = this.shippingInfoCheck.bind(this);
    this.showErrorInput = this.showErrorInput.bind(this);
    this.fetchPaymentSectionHeader = this.fetchPaymentSectionHeader.bind(this);
    this.focusModal = this.focusModal.bind(this);
    this.taxesCalculator = this.taxesCalculator.bind(this);
    this.calculateShippingCosts = this.calculateShippingCosts.bind(this);
    this.fetchLanguage = this.fetchLanguage.bind(this);
  }

  componentDidMount() {
    this.loadStoredOrdersFromLocalStorage();
    localStorageFunctions("jDm9LcBpCl2JWkDYHgwJEw", "orderInProgress");
    localStorageFunctions("eApOtsVDv3CBJxgQ074pDisc", `${this.state.discountRate}`, "encObj");
    this.setState({"ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE": "Select"});
    localStorage.setItem("orderConfirmNumber", `${this.state.orderConfirmationNumber}`);
    this.setState({"MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw": this.props.currency === "CA$" ? "CA" : "US" })

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })

    if (typeof window.pintrk === "function") {
      window.pintrk('track', 'AddToCart', {
        value: parseFloat(this.props.calculateItemSubtotal()),
        order_quantity: 1,
        currency: 'CAD'
      });
    }

    if (typeof window.ttq.track === "function") {
      window.ttq.track("AddToCart", {
        content_id: '301',
        content_type: "product",
        quantity: 1,
        price: parseFloat(this.props.calculateItemSubtotal()),
        value: parseFloat(this.props.calculateItemSubtotal()),
        currency: 'CAD',
      });
    }

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.focusedModal !== this.state.focusedModal) {
      this.submitInfoToGtag('add_payment_info');
    }
  }

  submitInfoToGtag(tag) {
    window.gtag('event', tag, {
      'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
      'value': parseFloat(this.props.calculateItemSubtotal()),
      'currency': "CAD",
      'transaction_id': ''
    })
  }

  updatePriceInfoToLocalStorage(shoppingSubtotal, shippingCost, taxes, grandTotal) {
    //this will be updated for the USD dynamically for the future purposes
    localStorageFunctions("XD671979A1B043B8FKJLS41D8ECA38124521C95F416", "CAD");
    localStorageFunctions("JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29", `${shoppingSubtotal}`);
    localStorageFunctions("A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746", `${shippingCost}`);
    localStorageFunctions("XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064", `${taxes}`);
    localStorageFunctions("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD", `${grandTotal}`);
  };

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`;
  }

  paymentPageUserAnalytics() {
    analyticsDataToDB("payment-page", this.state);
  };

  loadStoredOrdersFromLocalStorage() {
    // // This function loads local storage and sets the local state
    var ordersToStore = localStorageFunctions("a1ysQM3xOEe9nGpQg5D5g", null);

    this.setState({
        shoppingItem: ordersToStore,
        // MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw: destinationCountry,
        // ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: destinationProvince,
      }
      , ()=> {

      //logs user data for analytics purposes
      // this.paymentPageUserAnalytics();



      if (this.state.shoppingItem == null) {
        // this.rerouteToOrderPage();
      } else {
        this.updateTotalAmountToLocalStorage();
      }


    }
    );
  };

  updateTotalAmountToLocalStorage() {
    var unencryptedTotalAmount = calculateTotalPrice("total", this.state.shoppingItem);

    this.setState({totalCost: unencryptedTotalAmount});
    localStorageFunctions("a7DqdNz46FaG4kHddQ11KNQ", unencryptedTotalAmount, "encObj");
  };

  updateState(objectToUpdate, objectSequence, objectKeyToUpdate, objectValue) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object
    stateToBeUpdated[objectSequence][objectKeyToUpdate] = objectValue;

    if (objectKeyToUpdate === "qItemQuantity") {
      if (objectValue === "0") {
        if (window.confirm('Do you want to remove your item?')) {
          this.deleteItem(objectToUpdate, objectSequence);
        } else {
          //DO NOTHING Because user don't wanna delete.
        }
      } else {
        this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage());
      }
    } else {
      this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage());
    }
  };

  updateLocalStorage() {
    localStorageFunctions("a1ysQM3xOEe9nGpQg5D5g", this.state.shoppingItem, "encObj");
    this.updateTotalAmountToLocalStorage();
  };

  deleteItem(objectToUpdate, sequence) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object
    stateToBeUpdated.splice(sequence, 1);
    this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage()
    );
  };

  deleteItemOption(objectToUpdate, sequence, itemElementToDelete) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object

    if (window.confirm(`Do you want to remove the ${itemElementToDelete}?`)) {
      if(stateToBeUpdated && itemElementToDelete === "mat opening") {
        stateToBeUpdated[sequence].dPrimaryWindowWidth = 0;
        stateToBeUpdated[sequence].ePrimaryWindowHeight = 0;
        stateToBeUpdated[sequence].fPrimaryWindowTop = 0;
        stateToBeUpdated[sequence].gPrimaryWindowBottom = 0;
        stateToBeUpdated[sequence].hPrimaryWindowLeft = 0;
        stateToBeUpdated[sequence].iPrimaryWindowRight = 0;
        stateToBeUpdated[sequence].lMatCuttingPrice = 0;
        stateToBeUpdated[sequence].mSecondaryMatWidth = 0;
        stateToBeUpdated[sequence].nSecondaryMatColour = "Not Selected";
        stateToBeUpdated[sequence].oSecondMattingPrice = 0;
        stateToBeUpdated[sequence].pSecondMatCuttingPrice = 0;
        this.setState({[objectToUpdate]: stateToBeUpdated}
        , () => this.updateLocalStorage()
        );
      } else if (stateToBeUpdated && itemElementToDelete === "second matting") {
        stateToBeUpdated[sequence].mSecondaryMatWidth = "Not Selected";
        stateToBeUpdated[sequence].nSecondaryMatColour = "Not Selected";
        stateToBeUpdated[sequence].oSecondMattingPrice = 0;
        stateToBeUpdated[sequence].pSecondMatCuttingPrice = 0;
        this.setState({[objectToUpdate]: stateToBeUpdated}
        , () => this.updateLocalStorage()
        );
      }
    } else {
      //DO NOTHING Because user don't wanna delete.fetchShippingChargesPrompt
    }
  };

  updatePromoCode(disCountRate) {
    this.setState({promoCodeApplied: true, discountRate: disCountRate, promoCodeUsed: this.state.promoCodeEntered}, () => {
      localStorageFunctions("eApOtsVDv3CBJxgQ074pDisc", `${this.state.discountRate}`, "encObj");
      localStorageFunctions("promoCodeUsed", `${this.state.promoCodeEntered}`, "encObj");
    });
  };

  evaluatePromoCode(value) {
    this.setState({promoCodeEntered: value}, () => {
      if (this.state.promoCodeEntered === "pinterest10" || this.state.promoCodeEntered === "Pinterest10") {
        this.updatePromoCode(0.1);
      } else if (this.state.promoCodeEntered.toUpperCase() === this.props.promoCode) {
          this.updatePromoCode(this.props.promoDecimals);
      }
    });
  };

  //
  // else if (this.state.promoCodeEntered === "BlackFriday2021" || this.state.promoCodeEntered === "blackfriday2021" || this.state.promoCodeEntered === "BLACKFRIDAY2021") {
  //   this.updatePromoCode(0.25);
  // }

  rerouteToOrderPage() {
    window.location.href="/";
  };

  fetchOrderedItems() {

    //this is a part of legacy code
    if (this.state.shoppingItem === null) {
      this.rerouteToOrderPage();
    }

    var forExMultiplier = 1.00;

    if (this.state.qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw === "USD") {
      forExMultiplier = cadUsdRate;
    }

    return <div>
      {this.state.shoppingItem.map((item, key) => {
        return  <div className={`order-summary-item-container`} key={`order-summary-item-container-${key}`}>

          <div className={`order-summary-subsection-container order-summary-item-breakdown`}>
            <div className={`float-left-section`}>
              Item {this.state.shoppingItem.length > 1 ? (key + 1) : ""}
            </div>

            <div className={`float-right-section order-summary-small-font`}>
              Quantity
              <input className={`order-summary-quantity-input-summary`} type={"text"}
                     value={this.state.shoppingItem[key].qItemQuantity}
                     onChange={(evt) => this.updateState("shoppingItem", key,  "qItemQuantity", evt.target.value)}
              />
            </div>
          </div>

          <div className={`order-summary-subsection-container order-summary-small-font`}>
            <div className={`float-left-section`}>
              Primary Mat ({item.bPrimaryMatWidth} <span> x </span> {item.cPrimaryMatHeight} {item.aMeasuringUnit}, <span className={`capitalize`}>{`${item.jPrimaryColour}`}</span>)
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw}</span>
              <span>{fetchPrice(item.kPrimaryMattingPrice * forExMultiplier)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={`order-summary-remove-item-container order-summary-small-font`}>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.updateState("shoppingItem", key,  "qItemQuantity", "0")}
            >
              Remove
            </div>
          </div>

          <div className={ (item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `no-display` : `order-summary-subsection-container order-summary-small-font`}>
            <div className={`float-left-section`}>
              Opening
              ({item.dPrimaryWindowWidth} <span> x </span> {item.ePrimaryWindowHeight} {item.aMeasuringUnit})
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw}</span>
              <span>{fetchPrice(item.lMatCuttingPrice * forExMultiplier)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={(item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `no-display` : `order-summary-remove-item-container order-summary-small-font`}>
            <div className={`float-left-section order-summary-indent`}>
              Top {item.fPrimaryWindowTop} |
              Bottom {item.gPrimaryWindowBottom} |
              Left {item.hPrimaryWindowLeft} |
              Right {item.iPrimaryWindowRight} ({item.aMeasuringUnit})
            </div>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.deleteItemOption("shoppingItem", key, "mat opening")}>
              Remove
            </div>
          </div>

          <div className={ (item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `order-summary-subsection-container order-summary-small-font order-summary-italic`: `no-display`}>
            No window opening for this item
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `order-summary-subsection-container order-summary-small-font order-summary-italic`: `no-display`}>
            No secondary matting for this item
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `no-display` : `order-summary-subsection-container order-summary-small-font` }>
            <div className={`float-left-section`}>
              Add Double Mat <span className={"capitalize"}>({`${item.nSecondaryMatColour}`})</span>
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw}</span>
              <span>{fetchPrice((item.oSecondMattingPrice + item.pSecondMatCuttingPrice)*secondMattingFactorialPrice)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `no-display` : `order-summary-remove-item-container order-summary-small-font` }>
            <div className={`float-left-section order-summary-indent`}>
              Matting Width {item.mSecondaryMatWidth} ({item.aMeasuringUnit})
            </div>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.deleteItemOption("shoppingItem", key, "second matting")}>
              Remove
            </div>
          </div>

        </div>
      })}
    </div>
  };

  moveToPaymentSection(shoppingSubtotal, shippingCost, taxes, grandTotal) {

    this.submitInfoToGtag('add_shipping_info');

    this.updatePriceInfoToLocalStorage(shoppingSubtotal, shippingCost, taxes, grandTotal);
    this.setState({focusedModal: "summary"});
    window.scrollTo(0, 0);
  };

  updateShippingInfo(evt, propsObjectName) {
    this.setState({[propsObjectName]: evt.target.value}, () => {
      this.updateShippingInfoToLocalStorage(propsObjectName);
    });
  };

  updateShippingInfoToLocalStorage(propsObjectName) {
    localStorageFunctions(`${propsObjectName}`, this.state[propsObjectName], "encObj");
  };

  addTextFields(cssClassName, placeholderContent, propsObjectName, hasError, fieldTitle) {
    return <div className={cssClassName}>
        <div className={"add-text-fields-text-field-title"}>
          {fieldTitle}
        </div>
        <TextField
            id={"inspectletIgnore"}
            autoComplete={"off"}
            error={hasError}
            placeholder={placeholderContent}
            value={this.state[propsObjectName]}
            variant={"outlined"}
            onChange={(evt) => {
                this.updateShippingInfo(evt, propsObjectName);
            }}/>
    </div>
  };

  addCountrySelector() {
    return <div className={"shipping-address-country"}>
      <FormControl variant={"filled"}>
        <Select
          id={"shipping-destination-country"}
          onChange={(evt) => {
            this.updateShippingInfo(evt, "MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw");
          }}
          value={this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw}
        >
          <MenuItem value={"Canada"}>Canada</MenuItem>
          <MenuItem value={"US"}>United States</MenuItem>
        </Select>
      </FormControl>
    </div>
  };

  showSpinner() {
    this.setState({fetchSpinner: true});
  };

  showErrorInput(input) {
    if (this.state.turnOffErrorChecker === false) {

      if (input === "ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE") {
        if (this.state[input] === "Select") {
          return true
        } else {
          return false
        }
      } else {
        return this.state[input] === "" ? true : false
      }

    } else {
      return false
    }
  };

  selectProvinceOrState() {
    if (this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw === "CA" ||
        this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw === "US") {
      var list = Object.keys(productDB.taxRates[this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw]);
      return <FormControl
          error={this.showErrorInput("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE")}
          variant="outlined" className={"province-state-selector-container"}>
        <div className={"add-text-fields-text-field-title"}>
          {this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw === "CA" ? "Province*" : "State*"}
        </div>
        <Select value={this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE}
            onChange={(evt) => {
              this.updateShippingInfo(evt, "ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE");
            }}
        >
          <MenuItem value={"Select"}>
            {this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw === "CA" ? "Please select / Veuillez sélectionner" : "Please select / Veuillez sélectionner"}
          </MenuItem>
          {
            list.map((item, key) => {
              return <MenuItem value={item} key={`select-province-state-${key}`} >
                {item}
              </MenuItem>
            })
          }
        </Select>
      </FormControl>
    } else {
      return <FormControl variant="outlined">
        <Select
            value={this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE}
            onChange={(evt) => {this.setState({ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: evt.target.value})}}
            className={"province-state-selector-details-input"}
        >
          <MenuItem value={"Select"}>Select Province or State</MenuItem>
        </Select>
      </FormControl>
    }
  };

  shippingInfoCheck(nextModalId) {
    //checks if following information section has been filled up or not;
    if (this.state.pickUpOrShipping === "pickup") {
      this.setState({focusedModal: "summary"});
      this.focusModal(nextModalId);
    } else if (
        // this is shipping not pickup
        this.state.eApOtsVDv3CBJxgQ074pEw !== "" &&
        // this is shipping not pickup
        this.state.pickUpOrShipping === "shipping" &&
        // address street check()
        this.state.yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc !== "" &&
        //shipping address city check
        this.state.MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk !== "" &&
        //province check
        this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE !== "Select" &&
        //postal code check
        this.state.ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78 !== "" &&
        // address country check
        this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw !== "" &&
        //not checking the recipient name, apt/suite address since you don't always need them.
        this.state.Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4 !== ""
    ) {
      // should do the setstate and move the option
      // this.setState({focusedModal: "summary"});
      // this.focusModal(nextModalId);

      let shoppingSubtotal = isNaN(this.props.calculateItemSubtotal()) ? 0.00 : this.props.calculateItemSubtotal();

      let shippingCost = parseFloat(this.calculateShippingCosts(this.props.calculateItemSubtotal(), this.props.shoppingCartItems, this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw, this.state.pickUpOrShipping));

      let taxes = parseFloat(this.taxesCalculator(shoppingSubtotal + shippingCost, this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw, this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE));
      //enables taxes amount to consistently show 2 digits of decimals
      // let taxesDirty = (Math.round(taxes * 100)/100);
      //enables taxes amount to consistently show 2 digits of decimals
      //once to to fixed, it will show the string form
      let taxesClean = taxes.toFixed(2);

      let grandTotal = parseFloat((shoppingSubtotal + shippingCost + taxes)).toFixed(2);



      // this.moveToPaymentSection(this.state.shoppingSubtotal, this.state.shippingCost, this.state.taxes, this.state.grandTotal);



      this.postDataToCustomerAddressBook();

      this.moveToPaymentSection(shoppingSubtotal, shippingCost, taxesClean, grandTotal);





    } else {
      //shows which fields are missing (needs to highlight the field)
      this.setState({turnOffErrorChecker: false});
      //turn or error checker and show which fields are missing in case of an error;
    }
  };

  postDataToCustomerAddressBook() {
    //once the address validation gets completed, we send the address to firebase.
    var orderConfNumberClean = `${this.state.orderConfirmationNumber.slice(this.state.orderConfirmationNumber.length - 7).replace(/ /g,'')}`;
    var content = `${this.state.eApOtsVDv3CBJxgQ074pEw},${this.state.Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4},${this.state.yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc},${this.state.yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc},${this.state.MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk},${this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE},${this.state.ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78},${this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw}`
    connectToDB("postAddress","customer-address", orderConfNumberClean, content);
  }

  parentSetStateCallback(obj, val) {
    this.setState({[obj]: val});
    if (obj === "pickUpOrShipping" && val === "pickup") {
      this.setState({ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: "BC"});
    } else if (obj === "focusedModal" &&  val === "payment") {
      // once this gets triggered, we should put all the payment's state value to the local storage
      // this.saveShoppingSummaryToLocalStorage();
    }
    this.saveShoppingSummaryToLocalStorage();
  };

  //when parent set state function is clicked for the parentSetStateCallback function, then we will trigger the function to save the order details, amount, shipping, tax, addresses and other extrated info from the this state and directly send that to the local storage for the payment page to use.
  //we need to make sure that the for the payment section, the amount will be updated and pinged to the stripe.
  //eventually, we will have to make sure that the section will be backed up on the firebase as the payment stage to prepare the case of the prod paid data losses.

  saveShoppingSummaryToLocalStorage() {
    //customer name
    var customerName = localStorageFunctions("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4", null);
    localStorageFunctions("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4", customerName);

    //customer email
    var customerEmail = localStorageFunctions("eApOtsVDv3CBJxgQ074pEw", null);
    localStorageFunctions("eApOtsVDv3CBJxgQ074pEw", customerEmail);

    //user phone number
    var customerPhoneNumber = this.state.yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr;
    localStorageFunctions("yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr", customerPhoneNumber);

    //discount applied? //which code used // and what's the discount factor used
    var discountApplied = this.state.promoCodeApplied;
    var promoCodeUsed = this.state.promoCodeEntered === "" ? "No Promo Code Used" : this.state.promoCodeEntered;
    var promoRateApplied = this.state.discountRate;
    localStorageFunctions("eApOtsVDv3CBJxgQ074pDisc", `${discountApplied}`);
    localStorageFunctions("JKLCJ7D0D0A9E18871A032FA6A30JX9E901CBBDE8", promoCodeUsed);
    localStorageFunctions("JUX7AA083A5BLB26E8BC965A30JX6398E7BA3DE6", `${promoRateApplied}`);

    // shipping options pickup or shipping?
    var pickUpOrShipping = this.state.pickUpOrShipping;
    localStorageFunctions("F5299BDA449B72EJJ8526ECS93579EB5C4CE14E2J7", pickUpOrShipping)

    //address 1
    var address = this.state.yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc;
    localStorageFunctions("yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc", address)

    // address 2
    var addressSuite = this.state.yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc;
    localStorageFunctions("yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc", addressSuite)

    var city = this.state.MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk;
    localStorageFunctions("MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk", city);

    // province state
    var province = this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE;
    localStorageFunctions("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE", province)

    // postal code
    var postalCode = this.state.ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78;
    localStorageFunctions("ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78", postalCode)


    // country
    var country = this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw;
    localStorageFunctions("MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw", country);
  };

  taxesCalculator(amount, countryCode, provincialCode) {
        //if country code is US, there will be no taxes


        if (countryCode === "US") {
            return 0.00
        } else {
            if (provincialCode !== null && provincialCode !== undefined ) {
                var taxRate = productDB.taxRates.CA[provincialCode];
                return (amount * taxRate).toFixed(2);
            }
        }
        //if else (canada), the tax rates will depend on provincial code, which comes from pricingDB
    };

  calculateShippingCosts(subtotalAmount, shippingItems, country, shippingOrPickup) {
    return 0;

    //shipping is free now to US and CAN

        // if (subtotalAmount >= 49 || shippingOrPickup === "pickup") {
        //     return 0;
        // } else {
        //     var maxShippingCost = 0;
        //     var countryCode = (country === "CA") ? 0 : 1;
        //     if (shippingItems) {
        //         var shippingItemList = Object.keys(shippingItems);
        //         shippingItemList.map((item, key) => {
        //             if (shippingItems[item].shipping[countryCode] > maxShippingCost) {
        //                 maxShippingCost = shippingItems[item].shipping[countryCode];
        //             };
        //         });
        //         return maxShippingCost.toFixed(2)
        //     } else {
        //         return null;
        //     };
        // };
    };

  fetchPaymentSectionHeader(text) {
    return <div className={"payment-section-container-header"}>
      {text}
    </div>
  };

  fetchAddressForConfirmationEmail() {
    return `${localStorageFunctions("yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc", null)} ${localStorageFunctions("yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc", null)}, ${localStorageFunctions("MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk", null)}, ${localStorageFunctions("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE", null)}, ${localStorageFunctions("ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78", null)}`;
  };

  fetchCheckoutSection(grandTotal, shippingCost, taxesDirty) {

    return <div className={this.state.focusedModal === "summary" ? "delivery-payments-section-shipping-payments-container" : "no-display"}
                id={"shopping-checkout-container"}>

      {this.state.fetchSpinner ? <div className={`show-spinner-container`}>
        <div className="loader"></div>
        <div className="loader-text">
          Processing your transaction<br/>
          Traitement de votre transaction
        </div>
      </div> : null}

      <div className={"payment-section-dividers"}>
        {/*<div className={`shipping-form-container-section-header`}>*/}
        {/*  Pay with Credit Card*/}
        {/*</div>*/}

        <div className={"review-page-reminder"}>
          <div className={"cart-slider-ending-main"}>
            {this.fetchLanguage("Votre commande ","Your order")}
            <b>{this.fetchLanguage(" n’est pas "," does not ")}</b>
            {this.fetchLanguage("pas livrée avec des cadres, des vitres ou des images. ","come with frames, glasses, or prints. ")}
            <br className={this.state.french ? "no-display" : ""}/>
            {this.fetchLanguage("Seulement vos passe-partout personnalisés.","Only your custom matboards.")}
          </div>
          <div className={"cart-slider-ending-subheading"}>
            {this.fetchLanguage("Si le passe-partout ne fonctionne pas ou est endommagé, faites-nous le savoir et nous remplacerons votre commande gratuitement.", "If the mat does not work or is damaged, let us know!")}<br/>
          </div>
        </div>

        <div className={"checkout-button"}>
          {/*<div className={`payment-processor-description`}>*/}
          {/*  {[stripe, discover, amex, mastercard, visa].map((logo, key) => {return <img className={"credit-card-company-logos"} alt={""} key={`cc-${key}`} src={logo}/>})}*/}
          {/*</div>*/}

          {/*// this.state.qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw === "CAD" ? (this.state.totalCost * (1 - this.state.discountRate)).toFixed(2) : (this.state.totalCost * this.state.cadUsdRate * (1 - this.state.discountRate)).toFixed(2)*/}

          <Checkout
              name={'Custom Matboards'}
              description={`CustomMat.ca | Online Order - ${localStorageFunctions("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE", null)}`}
              amount={this.props.fxConversion(grandTotal)}
              email={this.state.eApOtsVDv3CBJxgQ074pEw}
              currency={this.props.currency === "US$" ? "USD" : "CAD"}
              startSpinner={this.showSpinner}
              userName={localStorageFunctions("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4", null)}
              pickUpOrShipping={this.state.pickUpOrShipping}
              shoppingCartItems={JSON.stringify(this.props.shoppingCartItems)}
              discountCodeUsed={this.state.promoCodeApplied ? this.state.promoCodeEntered : null}
              orderReferenceNumber={this.state.orderConfirmationNumber}
              deliveryAddress={this.fetchAddressForConfirmationEmail()}
              quantityDiscountFactor={this.props.getDiscountMultiplier()}
              discountCodeDiscountFactor={(1 - this.state.discountRate)}
              itemsSubtotal={this.props.fxConversion(this.props.calculateItemSubtotal())}
              itemsShipping={this.props.fxConversion(shippingCost)}
              itemsTaxes={this.props.fxConversion(taxesDirty)}
              french={this.state.french}
              cadUsdRate={cadUsdRate}
          />

          <div className={`payment-processor-description`}>
            {this.state.french ? "Acceptez toutes les principales cartes de crédit et de débit." : "Accept all major credit and debit cards."}
            <br/><br/>
            {this.state.french ? "Les paiements sont traités en toute sécurité via Stripe." : "Payments are securely processed through Stripe."}
            <br/>
            {this.state.french ? "Vous êtes d’accord avec nos politiques lors du processus de paiement." : "You agree with our policies when you process Checkout."}
          </div>

        </div>
      </div>

    </div>
  };

  focusModal(className) {
    // className === 1 ? document.getElementById("shopping-summary-container").scrollIntoView() : document.getElementById("shopping-checkout-container").scrollIntoView()
  };

  render() {

    let preDiscountPrice = false;
    let shoppingSubtotal = isNaN(this.props.calculateItemSubtotal()) ? 0.00 : this.props.calculateItemSubtotal();

    if (this.state.promoCodeApplied) {
      preDiscountPrice = shoppingSubtotal;
      shoppingSubtotal = shoppingSubtotal * (1 - this.state.discountRate)
    }

    let shippingCost = parseFloat(this.calculateShippingCosts(this.props.calculateItemSubtotal(), this.props.shoppingCartItems, this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw, this.state.pickUpOrShipping));
    let taxes = parseFloat(this.taxesCalculator(shoppingSubtotal + shippingCost, this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw, this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE));
    //enables taxes amount to consistently show 2 digits of decimals
    let taxesDirty = (Math.round(taxes * 100)/100);
    //enables taxes amount to consistently show 2 digits of decimals
    //once to to fixed, it will show the string form
    let taxesClean = taxes.toFixed(2);

    let grandTotal = parseFloat((shoppingSubtotal + shippingCost + taxes)).toFixed(2);

    return <div className={`payments-container`}>

      <div className={"checkout-progress-bar"}>

        <div
            onClick={() => this.setState({focusedModal: "shipping"})}
            className={this.state.focusedModal === "shipping" ? "checkout-progress-option-selected checkout-progress-option-left" : "checkout-progress-option-left"}>
          {this.state.french ? "Adresse" : "Address"}
        </div>

        <div className={this.state.focusedModal === "summary" ? "checkout-progress-option-selected checkout-progress-option" : "checkout-progress-option"}>
          {this.state.french ? "Paiement + Révision" : "Payment + Review"}
        </div>

        <div className={"checkout-progress-option"}>
          {this.state.french ? "Confirmation" : "Confirmation"}
        </div>

      </div>

      <ShippingInfo
          focusedModal={this.state.focusedModal}
          parentSetStateCallback={this.parentSetStateCallback}
          addTextFields={this.addTextFields}
          pickUpOrShipping={this.state.pickUpOrShipping}
          selectProvinceOrState={this.selectProvinceOrState}
          fetchSpinner={this.state.fetchSpinner}
          shippingInfoCheck={this.shippingInfoCheck}
          showErrorInput={this.showErrorInput}
          fetchPaymentSectionHeader={this.fetchPaymentSectionHeader}
          MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw={this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw}
          focusModal={this.focusModal}
          turnOffErrorChecker={this.state.turnOffErrorChecker}
          promoCodeInfo={this.state}
          evaluatePromoCode={this.evaluatePromoCode}
          shoppingCartItems={this.props.shoppingCartItems}
          updateItemList={this.props.updateItemList}
          calculateItemSubtotal={this.props.calculateItemSubtotal}
          forPaymentApp={true}
          //country
          //province
          ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE={this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE}
          shippingCharges={this.state.shippingCharges}
          getDiscountMultiplier={this.props.getDiscountMultiplier}
          getTotalItemCount={this.props.getTotalItemCount}
          taxesCalculator = {this.taxesCalculator}
          calculateShippingCosts = {this.calculateShippingCosts}
          preDiscountPrice = {preDiscountPrice}
          shoppingSubtotal = {shoppingSubtotal}
          shippingCost = {shippingCost}
          taxes = {taxesClean}
          grandTotal = {grandTotal}
          french={this.state.french}
          orderReferenceNumber={this.state.orderConfirmationNumber}
          fxConversion={this.props.fxConversion}
          currency={this.props.currency}
          location={this.props.location}
      />

      <ShoppingSummary
          focusedModal={this.state.focusedModal}
          turnOffErrorChecker={this.state.turnOffErrorChecker}
          parentSetStateCallback={this.parentSetStateCallback}
          promoCodeInfo={this.state}
          evaluatePromoCode={this.evaluatePromoCode}
          shoppingCartItems={this.props.shoppingCartItems}
          updateItemList={this.props.updateItemList}
          calculateItemSubtotal={this.props.calculateItemSubtotal}
          forPaymentApp={true}
          //country
          MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw={this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw}
          //province
          ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE={this.state.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE}
          shippingCharges={this.state.shippingCharges}
          pickUpOrShipping={this.state.pickUpOrShipping}
          focusModal={this.focusModal}
          getDiscountMultiplier={this.props.getDiscountMultiplier}
          getTotalItemCount={this.props.getTotalItemCount}
          taxesCalculator = {this.taxesCalculator}
          calculateShippingCosts = {this.calculateShippingCosts}
          preDiscountPrice = {preDiscountPrice}
          shoppingSubtotal = {shoppingSubtotal}
          shippingCost = {shippingCost}
          taxes = {taxesClean}
          grandTotal = {grandTotal}
          fetchLanguage = {this.fetchLanguage}
          french={this.state.french}
          fxConversion={this.props.fxConversion}
          currency={this.props.currency}
          location={this.props.location}
          onGoingPromotion={this.props.onGoingPromotion}
          promotionRate={this.props.promotionRate}
          promoCode={this.props.promoCode}
      />

      {this.fetchCheckoutSection(grandTotal, shippingCost, taxesDirty)}

    </div>
  }

}